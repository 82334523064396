import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";
import CTA from "../sections/nightshift/CTA";
import PostDetails from "../sections/blog/PostDetails";

import heroImg from  "../assets/nightshift/blog-kubernetes-audit-events.jpeg";

const BlogRegular = () => {
  return (
    <>
      <PageWrapper>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-left">
              <Col lg="10">
                <Title variant="hero" className="text-center">Kubernetes: Receiving Kubernetes Audit Events</Title>
                <PostDetails 
                    heroImg={heroImg}
                    imgAlt="Kubernetes Audit Events"
                >
                    <p>The Kubernetes Audit Subsystem is the de-facto standard for answering the ‘who what where why and how’ of things that are interacting with you cluster API. It’s an invaluable tool in the belt of a cluster operator. In this post, we explore an innovative way to receive these events with a RESTful API service, instead of collecting files on disk. </p>
                    <h3>Webhook Back End</h3>
                    <p>The Webhook back end is a very useful, though lightly documented feature that enables the K8’s control plane to stream audit events as they are generated.</p>
                    <p></p>
                </PostDetails>
              </Col>
            </Row>
          </Container>
        </Section>
        <CTA />
      </PageWrapper>
    </>
  );
};
export default BlogRegular;
